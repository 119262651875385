<template>
    <v-toolbar flat max-height="60px" class="mb-4">
        <v-toolbar-title class="text-caption text-sm-h5 font-italic text-uppercase font-weight-light">
            <v-icon color="yellow darken-2" :large="$vuetify.breakpoint.mobile" x-large>mdi-pause</v-icon>
            <span class="mr-2">code</span>
        </v-toolbar-title>
        <v-btn :x-small="$vuetify.breakpoint.mobile" text to="/" class="ml-xl-12">
            <span class="mr-xl-2 font-weight-light">Home</span>
        </v-btn>

        <v-menu>
            <template v-slot:activator="{ attrs, on }">
                <v-btn :x-small="$vuetify.breakpoint.mobile" text v-bind="attrs" v-on="on" class="mr-xl-12">
                    <span class="mr-xl-2 font-weight-light font-italic">Tooling</span>
                </v-btn>
            </template>
            <v-list nav :dense="$vuetify.breakpoint.mobile">
                <v-list-item v-for="tool in tools" :key="`${tool.index}`" :href="tool.to">
                    <v-list-item-content>
                        <v-list-item-title v-text="tool.title"></v-list-item-title>
                        <v-list-item-subtitle class="text--primary" v-text="tool.headline"></v-list-item-subtitle>
                        <v-list-item-subtitle v-text="tool.subtitle"></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-menu>
            <template v-slot:activator="{ attrs, on }">
                <v-btn :x-small="$vuetify.breakpoint.mobile" text v-bind="attrs" v-on="on" class="mr-xl-12">
                    <span class="mr-xl-2 font-weight-light font-italic">Other Projects</span>
                </v-btn>
            </template>
            <v-list nav :dense="$vuetify.breakpoint.mobile">
                <v-list-item v-for="other in others" :key="`${other.index}`" :href="other.to">
                    <v-list-item-content>
                        <v-list-item-title v-text="other.title"></v-list-item-title>
                        <v-list-item-subtitle class="text--primary" v-text="other.headline"></v-list-item-subtitle>
                        <v-list-item-subtitle v-text="other.subtitle"></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-btn :x-small="$vuetify.breakpoint.mobile" v-if="$auth.isAuthenticated" outlined text to="/dashboard">
            <span class="mr-xl-2 font-weight-light">Dashboard</span>
        </v-btn>
        <v-btn :x-small="$vuetify.breakpoint.mobile" v-if="$auth.isAuthenticated" text outlined to="/debugger">
            <span class="mr-xl-2 font-weight-light">
                <v-icon v-if="!$vuetify.breakpoint.mobile" dense size="small" color="yellow darken-2" class="mr-2">fa-bug</v-icon>debugger
            </span>
        </v-btn>

        <v-spacer></v-spacer>

        <template v-if="!$auth.isAuthenticated">
            <v-btn :x-small="$vuetify.breakpoint.mobile" icon tile class="mr-2">
                <v-icon @click="login" large>mdi-play</v-icon>
            </v-btn>
        </template>
        <template v-if="$auth.isAuthenticated">
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn :x-small="$vuetify.breakpoint.mobile" icon tile v-on="on" class="mr-2">
                        <v-avatar style="border-radius:4px" tile v-if="$auth.isAuthenticated">
                            <img v-if="!$vuetify.breakpoint.mobile" :src="$auth.user.picture" :alt="$auth.user.name" />
                        </v-avatar>
                        <v-icon v-if="$auth.isAuthenticated" :small="$vuetify.breakpoint.mobile" large class="ml-xl-3 mr-6">mdi-dots-vertical</v-icon>
                        <v-icon v-if="!$auth.isAuthenticated" to="/dashboard" :small="$vuetify.breakpoint.mobile" large>mdi-play</v-icon>
                    </v-btn>
                </template>
                <v-list width=200>
                    <v-list-item v-if="$auth.isAuthenticated" @click="logout">
                        <v-list-item-title>Sign Out</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </v-toolbar>
</template>

<script>
export default {
    name: "NavBar",
    data() {
        return {
            tools: [
                {
                    index: 0,
                    title: "Node.js V8 --inspector Manager (NiM)",
                    headline: '',
                    subtitle: 'Streamline Your Node.js Debugging Workflow with this Chromium Extension',
                    to: "https://nim.june07.com",
                },
                {
                    index: 1,
                    title: "Ngrok Redirect Service",
                    headline: '',
                    subtitle: 'Static redirect for ngrok tunnels.',
                    to: "https://ngrok-dns.june07.com",
                },
                {
                    index: 2,
                    title: "Policies",
                    headline: '',
                    subtitle: 'Single Click, Git Hosted, Policy Documents',
                    to: "https://policies.june07.com/",
                },
                {
                    index: 3,
                    title: "Hacktoberfest Opt-In/Out Tool",
                    headline: '',
                    subtitle: 'Fastest way to opt-in/out of Hacktoberfest',
                    to: "/hacktoberfest",
                },
                {
                    index: 4,
                    title: "Jenkinsfile (Sublime Text Plugin)",
                    subtitle: `Lint Jenkins' Jenkinsfile via SSH`,
                    to: "https://packagecontrol.io/packages/Jenkinsfile",
                },
            ],
            others: [
                {
                    index: 0,
                    title: "DaD",
                    headline: '',
                    subtitle: 'The gig messenger platform that speaks human.',
                    to: "https://dad.june07.com/",
                },
                {
                    index: 1,
                    title: "BLUR",
                    headline: '',
                    subtitle: 'Blur YOUR Home Across the Internet — SIMPLY',
                    to: "https://blur.june07.com/",
                },
            ]
        }
    },
    methods: {
        login() {
            this.$auth.loginWithRedirect({
                appState: { targetUrl: "/dashboard" },
            })
        },
        logout() {
            this.$auth.logout()
            this.$router.push({ path: "/" })
        },
    },
}
</script>

<style>
#mobileAuthNavBar {
    min-height: 125px;
    justify-content: space-between;
}
</style>