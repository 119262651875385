<template>
  <v-app id="">
    <nav-bar />
    <v-main>
      <error/>
      <keep-alive>
        <router-view/>
      </keep-alive>
    </v-main>
    <nav-footer />
  </v-app>
</template>

<script>
  import NavBar from "./components/NavBar";
  import NavFooter from './components/NavFooter';
  import Error from "./components/Error";
    import posthog from 'posthog-js'

    posthog.init('phc_BeaUG1LzVMKbIX7chUecCndvF3fDviu4EMhjVnMOdQ5',
        {
            api_host: 'https://us.i.posthog.com',
            person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
        }
    )

  export default {
    components: {
      NavBar,
      NavFooter,
      Error
    }
  }
</script>